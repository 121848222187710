.active-screen {
  display: block;
}
.UI-is-idle .active-screen {
  display: none;
}
.selection-page-container .idle-screen {
  height: 100%;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #000;
}
.user-is-working .idle-screen {
  display: none;
}

.add-video {
  position: relative;
  top: 0;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  left: 0;
}
.home-screen-container {
  position: relative;
}
.idle-screen {
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100%;
  background-color: #000;
}
.swipe-finger {
  position: absolute;
  top: 200px;
  left: 100px;
}
.swipe-finger img {
  width: 200px;
  height: 200px;
  opacity: 0;
  position: relative;
  /* animation: imageTransition 12s infinite; */
}
.swipe-finger p {
  position: absolute;
  top: -20px;
  left: 0;
  width: 0ch;
  height: 24px;
  color: #fff;
  /* animation: textTransition 12s infinite; */
  background-color: #000;
  overflow: hidden;
  font-size: 24px;
  letter-spacing: 1px;
  word-spacing: 5px;
}
.screeen-saver-button {
  flex: 1;
  position: absolute;
  bottom: 10px;
  z-index: 9999;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.screeen-saver-button h2 {
  padding: 20px 100px;
  border-radius: 25px;
  background-color: #02d55d;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 5px;
  font-size: 40px;
}

@keyframes imageTransition {
  /* 0% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50%,
  100% {
    transform: translate(140%, 0%);
    opacity: 0;
  } */
}
@keyframes textTransition {
  /* 0% {
    width: 0;
  }
  40% {
    width: 30ch;
  }
  45%,
  100% {
    width: 0ch;
  } */
}
