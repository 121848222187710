.withImageProductCard {
  border-radius: 16px;
  height: 256px;
  width: 190px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.withImageProductCard::before {
  background: var(--wendor-light-red) !important;
}

.new-ui-added-to-cart::before {
  background: var(--wendor-primary-red) !important;
}

.new-ui-item-pos {
  position: absolute;
  left: 0%;
  right: 68.97%;
  top: 0%;
  bottom: 86.49%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(145, 158, 171, 0.3), 0px 12px 24px -4px rgba(145, 158, 171, 0.2);
  border-radius: 16px 0px;
  z-index: 1;
}

.sold-out-product-parent::before {
  background: var(--wendor-grey-400) !important;
}

.sold-out-product-parent:active {
  transform: none !important;
}

.sold-out-product-parent:active::before {
  transform: translate3d(0, 0.75em, -1em) !important;
}

.sold-out-product {
  filter: grayscale(100%);
}

.sold-out-product-img {
  position: absolute;
  top: 0;
  right: 0;
}

.withImageProductCardImage {
  height: 160px;
  width: 200px;
  object-fit: contain;
  padding: 0px 20px;
}

.no-product-img-parent {
  height: 160px;
}

.no-product-img {
  max-height: 100px;
  width: 200px;
  object-fit: contain;
}

.new-ui-empty-card {
  color: #333;
}

.new-uicard-body {
  padding-top: 12px;
  height: 100%;
}

.item-pos-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-ui-selected-product-card {
  color: #d80000;
}

.new-ui-unselected-product-card {
  color: #d80000;
}

.new-ui-product-position {
  font-weight: bold;
}

.new-ui-product-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.new-ui-product-name {
  margin: 0;
  color: #333;
  max-width: 150px;
  padding: 5px 0px;
}

.new-ui-product-price-quantity {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px;
}

.new-ui-product-price h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
  width: 25%;
}

.new-ui-cart-row {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 65%;
  border-radius: 16px;
  height: 36px;
  border-radius: 8px;
}

.new-ui-cart-row-added {
  background-color: #d80000;
}

.edit-cart-quantity {
  width: 30%;
}
