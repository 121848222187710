.selection-page-container {
  display: flex;
  flex-direction: column;
}

/** 
 * Title row
 */

.title-image {
  vertical-align: baseline !important;
}

.title-text {
  font-size: 40px;
  font-weight: 800;
  color: $color-red;
}

.title-container {
  margin: auto;
  text-align: center;
}

/** 
 * Product Cards 
 */
.products-display-container {
  padding-top: 20px;
}

.product-card {
  box-shadow: -3px 1px 4px 0px #bbb;
  height: 90px !important;
  width: 90px !important;
  border-radius: 33%;
  margin: 0px 0;

  .card-body {
    text-align: center;
    padding: 0.2rem;
  }

  .card-title {
    line-height: 1.3;
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .item-pos-name {
    margin-left: 5px;
    text-align: center;

    .product-cart-quantity {
      display: none;
    }
  }

  .wendor-price {
    padding: 6px 14px;
    font-size: 18px;
    background-color: hsla(143, 30%, 90%, 1) !important;
    color: hsla(143, 56%, 24%, 1) !important;
    border: 0px;
    border-radius: 20px;
  }
}

.product-card::after,
.product-card::before {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

// /----------With Image Product Card----------//

.unselected-withimage-card-display {
  background: #948f8f;
  height: 252px;
  width: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 4px;
  border-radius: 16px;
}

// /-------------------------------------------//

.p-card {
  transition: 0.4s;
  margin: 10px auto;

  .selected-product-card {
    background-color: $color-green;
    color: white;
  }

  .unselected-product-card {
    background-color: white;
    color: $color-red;
  }
}

.p-card-newuiwithphoto {
  transition: 0.4s;
  margin: 10px auto;

  .selected-product-card {
    background-color: $color-green;
    color: white;
  }

  .unselected-product-card {
    background-color: white;
    color: $color-red;
  }
}

.selected-product-card {
  border: 1px solid var(--colorShadeD) !important;
}

/*----------New Product Card----------*/

.p-card-animated {
  width: 88px;
  transition: 0.4s;
  margin: auto;
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);

  .selected-product-card {
    color: white;
  }

  .unselected-product-card {
    background-color: white;
    color: $color-red;
  }
}

.p-card-animated::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d800004d;
  border-radius: 16px;
  box-shadow: 0 0 0 px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.p-card-animated:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

.p-card-animated:active {
  transform: translate(0em, 0.75em);
}

.p-card-animated:active::before {
  transform: translate3d(0, 0, -1em);
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.p-card-animated-without-scroll {
  float: left;
  width: 88px;
  transition: 0.4s;
  margin: 16px 35px 16px;
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);

  .selected-product-card {
    color: white;
  }

  .unselected-product-card {
    background-color: white;
    color: $color-red;
  }
}

.p-card-animated-without-scroll::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d800004d;
  border-radius: 16px;
  box-shadow: 0 0 0 px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.p-card-animated-without-scroll:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

.p-card-animated-without-scroll:active {
  transform: translate(0em, 0.75em);
}

.p-card-animated-without-scroll:active::before {
  transform: translate3d(0, 0, -1em);
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

/*------- Disabled product -------*/
.p-card-animated-disabled-without-scroll {
  float: left;
  width: 88px;
  transition: 0.4s;
  margin: 16px 35px 16px;
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
  pointer-events: none;
}

.p-card-animated-disabled-without-scroll::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: 16px;
  box-shadow: 0 0 0 px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.p-card-animated-disabled {
  width: 88px;
  transition: 0.4s;
  margin: auto;
  border: 2px solid var(--colorShadeA);
  border-radius: 16px;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
  pointer-events: none;
}

.p-card-animated-disabled::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: 16px;
  box-shadow: 0 0 0 px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  // transition: all 175ms cubic-bezier(0, 0, 1, 1);
  transition: all 150ms cubic-bezier(0, 0, 1, 1);
}

.p-card-animated-selected::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeD);
  border-radius: 16px;
  box-shadow: 0 0 0 px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

/*------- Disabled product ends -------*/

.product-card-animated {
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.12);
  height: 80px !important;
  width: 88px !important;
  border-radius: 16px;
  margin: 0px 0;

  .card-body {
    text-align: center;
    padding: 0.2rem;
  }

  .card-title {
    line-height: 1.3;
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .item-pos-name {
    margin-left: 5px;
    text-align: center;

    .product-cart-quantity {
      display: none;
    }
  }

  .wendor-price-animated {
    padding: 6px 0px;
    font-size: 18px;
    color: #555 !important;
    box-shadow: none;
    background-color: transparent;
  }
}

.wendor-price-animated-disable {
  padding: 6px 0px;
  font-size: 18px;
  color: #dfe3e8;
  box-shadow: none;
}

.product-position-animated {
  font-weight: bold;
  color: #333;
}

.product-position-animated-disable {
  font-weight: bold !important;
  color: #dfe3e8 !important;
}

.product-card-quantity-animated {
  margin-top: -16px;
  position: absolute;
  font-weight: 900 !important;
  z-index: 1;
  padding: 3px 6px !important;
  border-radius: 5px;
  color: white;
  background: $color-yellow;
  right: -15px;
}

.product-card-remove-animated {
  left: -16px;
  margin-top: -16px;
  position: absolute;
  font-weight: 900 !important;
  z-index: 1;
  padding: 1px 6px !important;
  border-radius: 5px;
  color: white;
  background: #d80000;
}

.product-card-remove-animated:hover {
  background: #d80000 !important;
  border-color: white;
}

.touch-animation-card {
  transform: translate(0em, 0.75em) !important;
  background-color: white !important;
  color: #333;
}

.touch-animation-card::before {
  transform: translate3d(0, 0, -1em) !important;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

/*----------New Product Card----------*/

.slides {
  position: relative;
}

.product-card-imshow {
  width: 254px;
  flex-direction: column;
  margin: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border: 1px solid #000;
  border-radius: 5px;

  .card-body {
    text-align: center;
    padding: 0;
  }

  .card-title {
    line-height: 1.3;
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .product-card {
    text-align: center;
    border: solid 3px black;
    height: 250px;
    width: 250px;
  }

  .product-name {
    font-size: 24px;
    line-height: 1.8em;
    color: #333;
    width: 100%;
    margin: 0;
  }

  .product-position {
    line-height: 1.3;
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: -160px;
    position: absolute;
    background: red;
    padding: 1px 18px;
    left: 40px;
    color: white;
  }

  .product-position::after {
    content: '';
    position: absolute;
    left: 5px;
    height: 35px;
    width: 40px;
    z-index: -1;
    background-color: darkred;
    transform: rotate(20deg);
    top: 10px;
  }

  .product-cart-quantity {
    line-height: 1.3;
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: -160px;
    position: absolute;
    background: green;
    padding: 1px 18px;
    left: 270px;
    color: white;
  }

  .product-cart-quantity::after {
    content: '';
    position: absolute;
    left: 5px;
    height: 35px;
    width: 40px;
    z-index: -1;
    background-color: darkgreen;
    transform: rotate(-20deg);
    top: 10px;
  }

  .item-pos-display {
    margin: 0;

    .image-display {
      flex: 1;
      background-color: #fff;
    }
  }

  // .item-pos-name {
  //   flex: 1;
  //   margin-left: '5px';
  //   text-align: 'left';
  // }

  .slides {
    position: relative;
  }

  .wendor-price {
    background-color: black;
    color: white;
    width: 100%;
    padding: 7px;
    display: block;
    font-weight: 800;
    font-size: 23px;

    .discount-price {
      display: float;
      text-decoration: line-through;
      color: darkred;
      padding-right: 5px;
    }
  }
}

.product-card-newuiwithphoto {
  width: 254px;
  flex-direction: column;
  margin: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border: 1px solid #000;
  border-radius: 5px;

  .card-body {
    text-align: center;
    padding: 0;
  }

  .card-title {
    line-height: 1.3;
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .product-card {
    text-align: center;
    border: solid 3px green;
    height: 250px;
    width: 250px;
  }

  .product-name {
    font-size: 24px;
    line-height: 1.8em;
    color: #333;
    width: 100%;
    margin: 0;
  }

  .product-position {
    line-height: 1.3;
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: -160px;
    position: absolute;
    background: red;
    padding: 1px 18px;
    left: 40px;
    color: white;
  }

  .product-position::after {
    content: '';
    position: absolute;
    left: 5px;
    height: 35px;
    width: 40px;
    z-index: -1;
    background-color: darkred;
    transform: rotate(20deg);
    top: 10px;
  }

  .product-cart-quantity {
    line-height: 1.3;
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: -160px;
    position: absolute;
    background: green;
    padding: 1px 18px;
    left: 270px;
    color: white;
  }

  .product-cart-quantity::after {
    content: '';
    position: absolute;
    left: 5px;
    height: 35px;
    width: 40px;
    z-index: -1;
    background-color: darkgreen;
    transform: rotate(-20deg);
    top: 10px;
  }

  .item-pos-display {
    margin: 0;

    .image-display {
      flex: 1;
      background-color: #fff;
    }
  }

  // .item-pos-name {
  //   flex: 1;
  //   margin-left: '5px';
  //   text-align: 'left';
  // }

  .slides {
    position: relative;
  }

  .wendor-price-newuiwithphoto {
    background-color: black;
    color: white;
    width: 100%;
    padding: 7px;
    display: block;
    font-weight: 800;
    font-size: 23px;

    .discount-price {
      display: float;
      text-decoration: line-through;
      color: darkred;
      padding-right: 5px;
    }
  }
}

/* .slick-prev, .slick-next {
  position: absolute;
  background-color: #eee;
  color:#000;
  top: 50%;
}*/

.tray-row {
  float: left;
  margin: 0 0 16px 0;
  width: 100%;
  position: relative;
  // border-bottom: 20px solid #d800001a;
}

.row-footer {
  background: #ead5d8;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: -1;
}

.with-image-tray-row {
  margin: 0px;
}

/** 
 * Card buttons
 */

.left-card-button,
.right-card-button {
  z-index: 9999;
  top: 15px;
  width: 30px;
  height: 30px;
  border-width: 1px;
  text-align: center;
  border: 2px solid #fff;
}

.product-card-quantity {
  margin-top: -12px;
  position: absolute;
  font-weight: 900 !important;
  z-index: 1;
  padding: 3px 6px !important;
  border-radius: 50%;
  color: white;
  background: $color-yellow;
  right: 7px;
}

.product-card-remove {
  left: 8px;
  margin-top: -12px;
  position: absolute;
  font-weight: 900 !important;
  z-index: 1;
  padding: 1px 6px !important;
  border-radius: 50%;
  color: white;
  background: #d80000;
}

.product-card-remove:hover {
  background: #d80000 !important;
  border-color: white;
}

.ui.disabled.button {
  // Increase opacity of disabled buttons
  opacity: 1 !important;
}

/** 
    * Product page pay and reset buttons 
   */

.product-display-buttton-container {
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.product-display-buttton-container-new {
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  background: white;
  bottom: -15px !important;
  position: fixed;
  height: 180px;
  width: 100%;
  padding-top: 40px;
}

/** 
    Small machine UI  
   */

.carousel .thumbs-wrapper {
  margin: 20px !important;
  overflow: hidden !important;
  text-align: center !important;
}

.carousel .slide .legend {
  opacity: 1 !important;
}

/*
arrows for scroll
**/
ion-icon {
  background: #fff;
  border-radius: 50%;
}

.empty-card {
  color: $color-grey !important;
}

.empty-card-row-select {
  color: $color-grey !important;
}

.empty-card {
  .wendor-price {
    color: $color-grey !important;
    background: #ccc !important;
  }

  .wendor-price-rectangle {
    color: $color-grey !important;
    background: #ccc !important;
  }
}

.cart-full-message {
  background: #fff;
  padding: 10px;
  z-index: 2;
  position: relative;
}
