.footer {
  display: flex;
  justify-content: center;
  height: 35px;
  bottom: -15px !important;
  position: fixed;
  margin: 24px auto !important;
  padding: 0px !important;
  width: 100%;
  z-index: 1;
}

.absolute-bottom-text {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
}
